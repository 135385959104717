import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: true
    }

    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    registro = {
        enableCarreras: true,
        universidades: ["Universidad de O'Higgins"],
        amount: 3,
        enableTelefono: true,
        enableNivel: true,
        enableColegio: true,
        nombresObligatorio: true,
        apellidoPaternoObligatorio: true,
        apellidoMaternoObligatorio: false,
        carrerasObligatorio: true,
        telefonoObligatorio: true,
        nivelObligatorio: true,
        colegioObligatorio: true
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmallLight",
                logoMobile: "logoSquare"
            },
            footer: {
                logo: "logoSmallLight"
            },
            landing: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/cdbcd7bded9f6757de26b9c61ae8d55d2000x2000.png",
                logo: "logoLargeLight",
                subtitle: "",
                subtitleHasShadow: null,
                logoFooter: "logoSmallLight"
            },
            sharedLayout: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/a60b1b6f76574436003c985f5858dc7c2000x2000.png",
                logo: "logoLarge"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "editar-prueba",
                    name: "Ejercita",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                }
            ]
        },
        showcasesByApp: {
            alumnos: [],
            profesores: []
        },
        menuConfig: {
            tipo: "showcasebox"
        },
        informacionContacto: [
            "Si tienes consultas o comentarios, escríbenos a contactouoh@ogr.cl"
        ]
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Comprensión lectora",
            asignaturaPlataforma: "Comprensión lectora",
            evaluacionTipo: ["paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias",
            clase: "ciencias",
            abreviacion: "Ciencias Naturales",
            asignaturaPlataforma: "Ciencias Naturales",
            evaluacionTipo: ["paes"],
            materialVisible: true
        },
        {
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia",
            clase: "historia",
            abreviacion: "Historia y Ciencias Sociales",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["paes"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "uoh",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/SomosUOH",
                twitter: "https://twitter.com/uohiggins?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
                youtube: "https://www.youtube.com/channel/UCL9gpmmqNNIIs3FpzxxX2ig",
                instagram: "https://www.instagram.com/admision_uoh/?hl=es-la"
            },
            soporte: {
                mail: "contactouoh@ogr.cl",
                telefono: null
            },
            contacto: {
                mail: "Si no puedes acceder escríbenos a contactouoh@ogr.cl",
                telefono: null
            },
            companyName: "Universidad de O'Higgins",
            companySite: "https://www.uoh.cl/"
        },
        evaluacionDefault: "paes",
        evaluacionTipoAdaptiveDefault: "paes",
        resumenGeneral: {
            curriculum: "curricular colombia",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular colombia",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: "logoSquareLight",
        canTextHighlight: true,
        identificadorUsuarioAlias: null,
        evaluacionResponder: "estandar",
        locale: "es-cl",
        disableZopim: true
    } as PlataformaType

    evaluaciones = {
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Habilidad PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Eje PAES": "Unidad Temática PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 295,
                    min: 100
                },
                {
                    max: 362,
                    min: 296
                },
                {
                    max: 433,
                    min: 363
                },
                {
                    max: 578,
                    min: 434
                },
                {
                    max: 852,
                    min: 579
                },
                {
                    max: 1000,
                    min: 853
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 100,
                tickInterval: 50,
                max: 1000
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o RUT",
        invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
