import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl,
        carreras: urls.carrerasUrl
    },
    googleAnalyticsPropertyId: {},
    // globalSiteTag: {
    //     alumnos: { propertyId: "G-PZ3TV4MC2Q", sendTo: "" }
    // },
    googleTagManagerPropertyId: {
        alumnos: "G-PZ3TV4MC2Q",
    },
    domain: {
        profesores: "docentes.uoh.ogr.cl",
        alumnos: "ensayos.uoh.cl",
        admin: "admin.uoh.ogr.cl"
    },
    facebookAnalyticsPropertyId: {},
    facebook: {
        appId: null,
        apiVersion: null
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: null,
    facebookApiVersion: null,
    domain: {
        profesores: "docentes.uoh.ogr.cl",
        alumnos: "ensayos.uoh.cl",
        admin: "admin.uoh.ogr.cl"
    }
}
